






























































import {Component, Vue} from "vue-property-decorator";
import TextInput from "../form/TextInput.vue";
import moment from "moment";
import FormButton from "@/components/form/Button.vue";
import DateInput from "@/components/form/DateInput.vue";
import ControlPanelMeetingFileList from "@/components/controlpanel/meetings/ControlPanelMeetingFileList.vue";
import ControlPanelMeetingList from "@/components/controlpanel/meetings/ControlPanelMeetingList.vue";

@Component({
  components: {
    ControlPanelMeetingList,
    ControlPanelMeetingFileList,
    DateInput,
    TextInput,
    FormButton,
  },
})
export default class BoardMeetingsControlPanel extends Vue {
  // New meeting
  public meetingName: string = "";
  public startDate: Date = new Date();
  public endDate: Date = null;
  public location: string = "";

  public meetingNameWarning = false;
  public startDateWarning = false;

  // Edit meeting
  public currentEditMeetingId: number = -1;
  public editMeetingName: string = "";
  public editStartDate: Date = new Date();
  public editEndDate: Date = null;
  public editLocation: string = "";

  public editMeetingNameWarning = false;
  public editStartDateWarning = false;

  // Meeting files
  public editMeetingFiles: any[] = [];
  public editMeetingFileName: string = "";

  public deleting: boolean = false;

  public validateNew() {
    this.meetingNameWarning = !this.meetingName;
    this.startDateWarning = !moment(this.startDate).isValid();

    return !this.meetingNameWarning && !this.startDateWarning;
  }

  public addMeeting() {
    if (this.validateNew()) {
      this.$store.dispatch("boardMeetings/addMeeting", {
        title: this.meetingName,
        startdate: moment(this.startDate).format("YYYY-MM-DD"),
        enddate: moment(this.endDate).isValid() ? moment(this.endDate).format("YYYY-MM-DD") : null,
        location: this.location,
      }).then(() => {
        this.meetingName = "";
        this.startDate = new Date();
        this.endDate = null;
        this.location = "";
        this.$modal.hide("add-meeting");
      });
    }
  }

  public editMeeting(meeting: any) {
    this.editMeetingName = meeting.title;
    this.editStartDate = meeting.startdate;
    this.editEndDate = meeting.enddate;
    this.editLocation = meeting.location;
    this.currentEditMeetingId = meeting.id;

    this.$modal.show("edit-meeting");
  }

  public editFiles(meeting: any) {
    this.editMeetingFiles = meeting.files;
    this.currentEditMeetingId = meeting.id;
    this.$modal.show("edit-meeting-files");
  }

  public validateEdit() {
    this.editMeetingNameWarning = !this.editMeetingName;
    this.editStartDateWarning = !moment(this.editStartDate).isValid();

    return !this.editMeetingNameWarning && !this.editStartDateWarning;
  }

  public submitEdit() {
    if (this.validateEdit()) {
      this.$store.dispatch("boardMeetings/editMeeting", {
        id: this.currentEditMeetingId,
        title: this.editMeetingName,
        startdate: moment(this.editStartDate).format("YYYY-MM-DD"),
        enddate: moment(this.editEndDate).isValid() ? moment(this.editEndDate).format("YYYY-MM-DD") : null,
        location: this.editLocation,
      }).then(() => {
        this.editMeetingName = "";
        this.editStartDate = new Date();
        this.editEndDate = null;
        this.editLocation = "";
        this.currentEditMeetingId = -1;
        this.$modal.hide("edit-meeting");
      });
    }
  }

  public async deleteMeeting() {
    if (confirm(`Are you sure you want to delete this board meeting? This will also delete related files. This action can not be undone.`)) {
      this.deleting = true;
      this.$store.dispatch("boardMeetings/deleteMeeting", this.currentEditMeetingId).then(() => {
        this.editMeetingName = "";
        this.editStartDate = new Date();
        this.editEndDate = null;
        this.editLocation = "";
        this.currentEditMeetingId = -1;
        this.deleting = false;
        this.$modal.hide("edit-meeting");
      });
    }
  }

  public addFile() {
    const fileInput = this.$refs.meetingFileInput as HTMLInputElement;

    if (this.editMeetingFileName && fileInput.value) {
      const formData = new FormData();
      formData.append("title", this.editMeetingFileName);
      formData.append("file", fileInput.files[0]);
      formData.append("meetingId", this.currentEditMeetingId.toString());

      this.$store.dispatch("boardMeetings/addFile", {meetingId: this.currentEditMeetingId, formData}).then((file) => {
        this.editMeetingFileName = "";
        fileInput.value = "";
      });
    }
  }

  public async deleteFile(file: any) {
    if (confirm(`Delete file ${file.title}?`)) {
      await this.$store.dispatch("boardMeetings/deleteFile", {file, meetingId: this.currentEditMeetingId});
    }
  }
}
